import axios from 'axios'
import { requireConfigValue } from '@framework/isomorphic-config'
import { Product, SearchProductsHook } from '@commerce/types/product'
import normalizeProduct, {
  normalizeIncluded,
} from '@framework/utils/normalizations/normalize-product'
import { IProducts } from '@spree/storefront-api-v2-sdk/types/interfaces/Product'
import qs from 'qs'

type GetProductsProps = {
  search?: string
  categoryId?: string
  brandId?: string
  sort?: string
  minPrice?: string
  maxPrice?: string
  brands?: string
  collection_filter?: string
  currentPage?: string
  taxon?: string | string[] | undefined
  locale?: string
  per_page?: string
}
const nextToSpreeSortMap: { [key: string]: string } = {
  'trending-desc': 'available_on',
  'latest-desc': 'updated_at',
  'price-asc': 'price',
  'price-desc': '-price',
  bestsellers: 'bestsellers',
}

let abortController: AbortController | undefined

export const getProducts = async ({
  search = '',
  categoryId = '',
  collection_filter = '',
  brandId = '',
  sort = '',
  minPrice = '',
  maxPrice = '',
  brands = '',
  currentPage = '',
  taxon = '',
  locale = '',
  per_page = '48',
}: GetProductsProps): Promise<SearchProductsHook['data']> => {
  if (abortController) {
    abortController.abort()
  }
  abortController = new AbortController()
  const apiUrl = requireConfigValue('apiHost')
  const taxons = [taxon || categoryId, brandId].filter(Boolean)
  const filter: any = {
    filter: {
      ...(taxons.length > 0 ? { taxons: taxons.join(',') } : {}),
      ...(search ? { name: search } : {}),
      ...(collection_filter ? { name: collection_filter } : {}),
    },
  }

  if (minPrice || maxPrice) {
    filter.filter.price = `${minPrice !== '0' ? minPrice : '0'}${
      maxPrice !== '0' && maxPrice !== '' ? `,${maxPrice}` : ',9999999'
    }`
  }

  if (brands.length > 0) {
    filter.filter['[properties][brand]'] = brands.toString()
  }

  filter.filter.in_stock = 'true'
  // const defaultSorting = '-available_on'
  const sorting = sort ? { sort: nextToSpreeSortMap[sort] } : {}
  const params = {
    include:
      'product_properties,primary_variant,images,taxons,taxons.parent.parent.parent',
    per_page: Number(per_page),
    ...filter,
    ...sorting,
    // image_transformation: {quality: 100, size: '1000x1000'},
    page: currentPage || 1,
  }

  console.log(params)
  const serialize = qs.stringify(params)

  const {
    data: res,
  }: { data: IProducts & { meta?: { filters: any } | undefined } } =
    await axios.get(`${apiUrl}/api/custom/products?${serialize}`, { signal: abortController.signal })
  const normalizedIncluded: any = normalizeIncluded(res)
  const normalizedProducts: Product[] = res?.data?.map((spreeProduct: any) =>
    normalizeProduct(res, spreeProduct, normalizedIncluded),
  )

  const found = res.data.length > 0

  if (!found) {
    return { products: [], found }
  }

  return {
    products: found ? normalizedProducts : [],
    found,
    metaBrands: res?.meta?.filters?.product_properties?.filter(
      (item: any) => item.name === 'brand',
    )[0].values,
    totalPages: res?.meta?.total_pages,
    totalCount: res?.meta?.total_count,
  }
}
