import cn from 'classnames'
import { Carousel, Layout } from '@components/common'
import { Container } from '@components/ui'
import { Banner } from '@components/common/Banner'
import Link from 'next/link'
import { getCmsPages } from 'api/getCmsPages'
import { BANNER_ELEMENT_TYPES, CmsPage, CmsPages } from 'models'
import { ArrowDoubleRight } from '@components/icons'
import { useBrandThemeContext } from '@contexts/brandThemeContext'
import { useEffect, useState } from 'react'
import { ProductTypes } from '@commerce/types/product'
import { getBestSellers } from '@api/getBestSellers'
import { TOAST_OPTIONS } from '@constants/toastOptions'
import { toast } from 'react-toastify'
import Head from 'next/head'
import { useStoreDataContext } from '@contexts/storeDataContext'
import { BlogsSection } from '@components/BlogsSection'
import { HomeBrandBottomBanner } from '@components/common/Banner/HomeBrandBottomBanner'
import { HomePageInfoSection } from '@components/HomePageInfoSection'
import { NewArrivalsCarousel } from '@components/NewArrivalsCarousel'

interface HomeProps {
  page: CmsPages | null
}

const breakpoints = {
  320: {
    slidesPerView: 1,
    spaceBetween: 16,
  },
  640: {
    slidesPerView: 2,
    spaceBetween: 30,
  },
  768: {
    slidesPerView: 3,
    spaceBetween: 30,
  },
  1200: {
    slidesPerView: 4,
    spaceBetween: 40,
  },
}

export async function getStaticProps() {
  try {
    const pagePromise = getCmsPages({ perPage: 1, page: 'homepage' })
    const { data: page } = await pagePromise

    return {
      props: {
        page,
      },
      notFound: page.meta.total_pages <= 0,
    }
  } catch {
    return {
      props: {},
    }
  }
}

const SmallAndXSBannersSection = ({
  smallBanners,
  xsBanners,
  isHomeBrand,
}: {
  smallBanners: CmsPage[]
  xsBanners: CmsPage[]
  isHomeBrand: boolean
}) => (
  <div>
    {isHomeBrand && (
      <h2 className='text-center sm:text-32 text-xl font-bold tracking-[5] leading-[50px] uppercase'>
        Categories
      </h2>
    )}

    <div
      className={cn('flex flex-wrap -mx-4 sm:mt-12 xs:mt-6', {
        'md:flex-nowrap md:overflow-x-auto md:overflow-y-hidden': isHomeBrand,
      })}
    >
      {smallBanners.length > 0
        ? smallBanners.map(banner => (
            <Banner
              key={banner.attributes.link}
              title={banner.attributes.content.title}
              subtitle={banner.attributes.content.subtitle}
              buttonText={banner.attributes.content.button_text}
              link={banner.attributes.link}
              background={`${banner.attributes.img_one_lg}`}
              size='small'
              isCentered={false}
              isHomeBrand={isHomeBrand}
            />
          ))
        : null}
      {xsBanners.length > 0
        ? xsBanners.map(banner => (
            <Banner
              key={banner.attributes.link}
              title={banner.attributes.content.title}
              subtitle={banner.attributes.content.subtitle}
              buttonText={banner.attributes.content.button_text}
              link={banner.attributes.link}
              background={`${banner.attributes.img_one_lg}`}
              size='xsmall'
              isCentered={false}
              isHomeBrand={isHomeBrand}
            />
          ))
        : null}
    </div>
  </div>
)

const Home = ({ page }: HomeProps) => {
  const [bestSellers, setBestSellers] = useState<ProductTypes['product'][]>([])
  const pageBanners =
    (page?.included.map(el => ({
      attributes: {
        ...el.attributes,
        content: JSON.parse(JSON.stringify(el.attributes.content || {})),
      },
    })) as CmsPage[]) || []
  const {
    data: {
      attributes: { is_home_brand: isHomeBrand },
    },
  } = useStoreDataContext()

  useEffect(() => {
    const getProducts = async () => {
      try {
        const { products } = await getBestSellers({ limit: '10' })

        setBestSellers(products)
      } catch (error) {
        toast.error('Something went wrong', TOAST_OPTIONS)
      }
    }

    getProducts().then()
  }, [])

  const {
    xs_banner_number: xsBannersNumber,
    small_banner_number: smallBannersNumber,
  } = useBrandThemeContext()
  const mainBanner =
    pageBanners.find(
      el =>
        el.attributes.content.banner_element ===
        BANNER_ELEMENT_TYPES.MAIN_BANNER,
    ) || pageBanners[0]
  let xsBanners = pageBanners.filter(
    el =>
      el.attributes.content.banner_element === BANNER_ELEMENT_TYPES.XS_BANNER,
  )
  let smallBanners = pageBanners.filter(
    el =>
      el.attributes.content.banner_element ===
      BANNER_ELEMENT_TYPES.SMALL_BANNER,
  )

  let footerBanner = pageBanners.find(
    el =>
      el.attributes.content.banner_element ===
      BANNER_ELEMENT_TYPES.FOOTER_BANNER,
  )

  const blogBanners = pageBanners.filter(
    el =>
      el.attributes.content.banner_element === BANNER_ELEMENT_TYPES.BLOG_BANNER,
  )

  const bottomBanner = pageBanners.find(
    el =>
      el.attributes.content.banner_element ===
      BANNER_ELEMENT_TYPES.BOTTOM_BANNER,
  )

  //Delete once all banners have correct type
  if (smallBanners.length === 0) {
    smallBanners = pageBanners?.slice(
      xsBannersNumber + 1,
      xsBannersNumber + 1 + smallBannersNumber,
    )
  }

  //Delete once all banners have correct type
  if (xsBanners.length === 0) {
    xsBanners = pageBanners?.slice(1, xsBannersNumber + 1)
  }

  const bestSellersLabel = isHomeBrand ? 'TRENDING THIS MONTH' : 'BESTSELLERS'

  return (
    page && (
      <>
        <Head>
          <link
            rel='preload'
            as='image'
            href={pageBanners[0]?.attributes.img_one_lg || ''}
          />
        </Head>
        <div className='flex w-full flex-col'>
          {mainBanner && (
            <Banner
              title={pageBanners[0].attributes.content.title}
              subtitle={pageBanners[0].attributes.content.subtitle}
              buttonText={pageBanners[0].attributes.content.button_text}
              link={pageBanners[0].attributes.link}
              background={`${pageBanners[0].attributes.img_one_lg}`}
              isHomeBrand={isHomeBrand}
              size='big'
            />
          )}
          <Container>
            <div
              className={cn('max-w-[1240px] my-0 mx-auto  pb-10 px-4', {
                'sm:mb-16': !isHomeBrand,
              })}
            >
              <div className='flex items-center relative sm:pt-20 pt-10 mb-3.5 lg:mb-11  justify-center'>
                <h2 className='sm:text-32 text-xl sm:tracking-widest font-bold block  lg:text-center'>
                  {bestSellersLabel}
                </h2>
                <div
                  className={cn(
                    'absolute right-0 bottom-1 text-sm cursor-pointer !text-viewAll hidden sm:block',
                    { 'sm:pr-10': isHomeBrand },
                  )}
                >
                  <Link href='/bestsellers?sort=bestsellers'>
                    <a className='flex items-center'>
                      View All <ArrowDoubleRight className='ml-3' />
                    </a>
                  </Link>
                </div>
              </div>
              <Carousel products={bestSellers} breakpoints={breakpoints} />
              <Link href='/bestsellers?sort=bestsellers'>
                <a className='flex items-center !text-viewAll sm:hidden'>
                  View All <ArrowDoubleRight className='ml-3' />
                </a>
              </Link>
              {!isHomeBrand && (
                <SmallAndXSBannersSection
                  smallBanners={smallBanners}
                  xsBanners={xsBanners}
                  isHomeBrand={isHomeBrand}
                />
              )}
            </div>
          </Container>
          {isHomeBrand && (
            <div className='px-4 py-4 sm:px-16 sm:py-10'>
              <SmallAndXSBannersSection
                smallBanners={smallBanners}
                xsBanners={xsBanners}
                isHomeBrand={isHomeBrand}
              />
            </div>
          )}
          {isHomeBrand && <NewArrivalsCarousel />}
          {isHomeBrand && (
            <div className='px-4 py-4 sm:px-16 sm:py-10'>
              <BlogsSection banners={blogBanners} />
            </div>
          )}
          {isHomeBrand && bottomBanner && (
            <HomeBrandBottomBanner banner={bottomBanner} />
          )}
          {isHomeBrand && <HomePageInfoSection />}
        </div>
        {footerBanner ? (
          <div
            style={{
              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url('${footerBanner.attributes.img_one_lg}'), lightgray 50% / cover no-repeat`,
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
            }}
            className='md:px-40 py-10 w-full text-white text-sm'
          >
            <div className='text-center font-bold max-w-[1240px] my-0 mx-auto'>
              {footerBanner.attributes.content.subtitle}
            </div>
          </div>
        ) : null}
      </>
    )
  )
}

Home.Layout = Layout
export default Home
