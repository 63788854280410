import { CmsPage } from '@models'
import { Button } from '@components/ui'
import Link from 'next/link'
import { ArrowRightCopperStudio } from '@components/iconSetCopperStudio/ArrowRightCopperStudio'
import { useBrandThemeContext } from '@contexts/brandThemeContext'

interface HomeBrandBottomBannerProps {
  banner: CmsPage
}
export const HomeBrandBottomBanner = ({
  banner,
}: HomeBrandBottomBannerProps) => {
  const { banner_overlay_opacity: bannerOverlayOpacity } =
    useBrandThemeContext()

  return (
    <div
      style={{
        background: `url('${banner.attributes.img_one_lg}') center center no-repeat`,
        backgroundSize: 'cover',
      }}
    >
      <div
        className='h-[541px] w-full flex items-center justify-center flex-col'
        style={{ background: `rgba(0,0,0,${bannerOverlayOpacity}%)` }}
      >
        <h2 className='font-bold text-center text-white lg:text-[70px] md:text-5xl text-32'>
          {banner.attributes.content.title}
        </h2>
        <p className='font-semibold md:text-xl text-base text-white py-10 text-center'>
          {banner.attributes.content.subtitle}
        </p>
        {banner.attributes.link ? (
          <Link href={banner.attributes.link} passHref>
            <Button
              data-testid='banner_shop_now_button'
              className=' lg:px-10 lg:py-5 lg:text-2xl lg:tracking-normal'
              variant='brand'
            >
              <div className='flex items-center gap-1'>
                <span className='mr-2'>
                  {banner.attributes.content.button_text}
                </span>
                <ArrowRightCopperStudio className='hidden md:block' />
              </div>
            </Button>
          </Link>
        ) : null}
      </div>
    </div>
  )
}
