import axios from 'axios'
import { requireConfigValue } from '@framework/isomorphic-config'
import qs from 'qs'
import { Product, SearchProductsHook } from '@commerce/types/product'
import normalizeProduct, {
  normalizeIncluded,
} from '@framework/utils/normalizations/normalize-product'

type GetBestSellersProps = {
  limit?: string
}
export const getBestSellers = async ({
  limit,
}: GetBestSellersProps): Promise<SearchProductsHook['data']> => {
  const apiUrl = requireConfigValue('apiHost')

  const params = {
    include:
      'product_properties,primary_variant,images,taxons,taxons.parent.parent.parent',
    image_transformation: { quality: 100, size: '1000x1000' },
    page: 1,
    per_page: 10,
    sort: 'bestsellers',
    'filter[in_stock]': true,
  }
  const serialize = qs.stringify(params)
  const { data: res } = await axios.get(
    `${apiUrl}/api/custom/products?${serialize}`,
  )

  const normalizedIncluded: any = normalizeIncluded(res)
  const normalizedProducts: Product[] = res?.data?.map((spreeProduct: any) =>
    normalizeProduct(res, spreeProduct, normalizedIncluded),
  )

  const found = res.data.length > 0

  if (!found) {
    return { products: [], found }
  }

  return {
    products: found ? normalizedProducts : [],
    found,
    metaBrands: res?.meta?.filters?.product_properties?.filter(
      (item: any) => item.name === 'brand',
    )[0].values,
    totalPages: res?.meta?.total_pages,
  }
}
