/* eslint-disable unused-imports/no-unused-vars */
import { ArrowDoubleRight } from '@components/icons'
import { Carousel } from '@components/common'
import { getProducts } from '@api/getProducts'
import Link from 'next/link'
import { Container } from '@components/ui'
import { useEffect, useState } from 'react'
import { Product } from '@commerce/types/product'

const breakpoints = {
  320: {
    slidesPerView: 1,
    spaceBetween: 16,
  },
  640: {
    slidesPerView: 2,
    spaceBetween: 30,
  },
  768: {
    slidesPerView: 3,
    spaceBetween: 30,
  },
  1200: {
    slidesPerView: 4,
    spaceBetween: 40,
  },
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fetchNewArrivals = async () => {
  const apiUrl = process.env.NEXT_PUBLIC_SPREE_API_HOST
  const data = await fetch(
    `${apiUrl}/api/v2/storefront/taxons/collections/new-arrivals`,
  )

  const taxon = await data.json()
  const newArrivals = await getProducts({
    taxon: taxon.data?.id,
    currentPage: '1',
    per_page: '10',
  })

  return newArrivals.products
}

const NEW_ARRIVALS_COLLECTION_NAME = 'new-arrivals'

export const NewArrivalsCarousel = () => {
  const [newArrivals, setNewArrivals] = useState<Product[]>([])

  useEffect(() => {
    fetchNewArrivals().then(data => {
      setNewArrivals(data)
    })
  }, [])

  if (newArrivals.length === 0) {
    return null
  }

  return (
    <Container>
      <div className='max-w-[1240px] my-0 mx-auto  pb-10 px-4'>
        <div className='flex items-center relative sm:pt-20 pt-10 mb-3.5 lg:mb-11  justify-center'>
          <h2 className='sm:text-32 text-xl sm:tracking-widest font-bold block  lg:text-center'>
            NEW ARRIVALS
          </h2>
          <div className='absolute right-0 bottom-1 text-sm cursor-pointer !text-viewAll hidden sm:block sm:pr-10'>
            <Link href={`/collections/${NEW_ARRIVALS_COLLECTION_NAME}`}>
              <a className='flex items-center !text-viewAll '>
                View All <ArrowDoubleRight className='ml-3' />
              </a>
            </Link>
          </div>
        </div>
        <Carousel products={newArrivals} breakpoints={breakpoints} />
        <Link href={`/collections/${NEW_ARRIVALS_COLLECTION_NAME}`}>
          <a className='flex items-center sm:hidden !text-viewAll'>
            View All <ArrowDoubleRight className='ml-3' />
          </a>
        </Link>
      </div>
    </Container>
  )
}
